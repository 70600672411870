import PageContents from '@/types/PageContents';

import Workshop from './Kalender-workshops.html';
import CaseReportDrErikTraupeVideo from './CaseReportDrErikTraupe-Video.html';
import CaseReportDrVincentHagelVideo from './CaseReportDrVincentHagel-Video.html';
import AtlasMagazin from './Atlas-magazin.html';

// const distanceX = 7.5;

const videoModalSize = {
  height: 'calc(315px + 4rem)',
  width: 'calc(560px + 4rem)',
};
console.log('page content');
const pageContent: PageContents[] = [
  {
    button: {
      // dddd
      top: '77%',
      left: '10.7%',
      type: 'open',
      textAlign: 'left',
      content: 'Workshops',
    },
    modal: {
      content: Workshop,
    },
  },
  {
    button: {
      top: '13%',
      left: '64%',
      type: 'video',
      content: 'Case Report - <br>Dr. Erik Traupe',
      textAlign: 'top',
    },
    modal: {
      ...videoModalSize,
      content: CaseReportDrErikTraupeVideo,
    },
  },
  {
    button: {
      top: '34%',
      left: '66%',
      type: 'video',
      content: 'Case Report - <br>Dr. Vincent Hagel',
      textAlign: 'bottom',
    },
    modal: {
      ...videoModalSize,
      content: CaseReportDrVincentHagelVideo,
    },
  },
  {
    button: {
      top: '23%',
      left: '23.7%',
      type: 'open',
      content: 'EndoTrainer Plus',
      textAlign: 'right',
      url: 'https://www.joimax.com/en/virtual-congress-2020/',
    },
    modal: {
      content: '',
    },
  },
  {
    button: {
      top: '35%',
      left: '85%',
      type: 'open',
    },
    modal: {
      content: AtlasMagazin,
    },
  },
  {
    button: {
      top: '51%',
      left: '84%',
      type: 'pdf',
      content: 'Abstract<br>Book',
      url: '/content/Abstract_Book_3.pdf',
    },
    modal: {
      content: '',
    },
  },
  {
    button: {
      top: '69%',
      left: '83%',
      type: 'pdf',
      content: 'Important<br>Scientific Studies',
      url: '/content/ImportantScientificStudies_Rev007_2021-02_inProgress.pdf',
    },
    modal: {
      content: '',
    },
  },
];

export default pageContent;
