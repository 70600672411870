
































import { Component, Vue } from 'vue-property-decorator';
import CircleButton from '@/components/CircleButton.vue';
import Modal from '@/components/Modal.vue';

import pageContent from '@/contents/education/index';

@Component({
  components: {
    CircleButton,
    Modal,
  },
})
export default class Education extends Vue {
  public modalOpen = '';

  public pageContent = pageContent;

  close(id: string) {
    this.modalOpen = this.modalOpen === id ? '' : id;
  }
}

